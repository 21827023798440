@import "../../../../mixins";
.app-footer {
  display: flex;
  justify-content: space-between;
  min-height: calc(20vh - 86px);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  margin: auto;
  width: fit-content;

  .app-footer-context {
    margin: 40px 200px;
    padding-top: 15px;
  }

  .social-links {
    margin: 40px;
    padding-top: 15px;
  }

  .footer-text {
    padding-top: 40px;

    span {
      font-weight: 300;
    }
  }

  a {
    position: relative;
    text-decoration: none;
    align-self: center;
    padding: 20px;
    font-size: 1.6rem;
    @include tablet {
      padding: 10px;
    }
    &.logo {
      font-size: 2.4rem;
      text-transform: uppercase;
      font-weight: 900;
    }
  }

  @include tablet {
    & {
      display: block;
      background-color: #1F1F1F;
      width: auto;
      text-align: center;
      padding: 10px;

      .social-links {
        margin-top: 20px;
      }

      .app-footer-context {
        margin: 0;
      }
    }

    .logo {
      margin-right: 0;
    }
  }
}
