@import "../../../mixins";
.App-header {
  display: flex;
  max-height: 90px;
  padding: 16px 32px !important;
  transition: background-color 1s ease;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  justify-content: center;
  background-image:  linear-gradient(rgba(#070707, 0.7), rgba(#070707, 0.7));
  color: #fff;
  z-index: 3;

  @include tablet {
    justify-content: space-between;
    padding: 36px 5% 0;
  }

  &.fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 7000;
  }

  a {
    position: relative;
    text-decoration: none;
    align-self: center;
    padding: 20px;
    font-size: 1.6rem;
    @include tablet {
      padding: 10px;
    }
    &.logo {
      font-size: 2.4rem;
      text-transform: uppercase;
      font-weight: 900;
      margin-right: 100px;

      @include tablet {
        margin-right: 0;
      }
    }
  }

  .navigation {
    display: flex;
    white-space: nowrap;

    @include tablet {
      display: none;
    }

    &.mobile-version {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 90px;
      left: 0;
      right: 0;
      bottom: 0;
      background-image:  linear-gradient(rgba(249, 0, 0, 0.1), rgba(249, 0, 0, 0.1)), url("../../../resources/img/fst-sc.jpeg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-clip: border-box;
      background-size: cover;
      padding-top: 4rem;
    }
    a {
      align-self: center;
      position: relative;
      @include tablet {
        font-size: x-large;
      }
      &:before {
        position: absolute;
        bottom: 10px;
        left: 20px;
        content: '';
        width: 0;
        height: 5px;
        background: transparent;
        transition: width 0.2s ease-out;
        @include tablet {
          left: 10px;
          bottom: 2px;
        }
      }
      &.active-link {
        &:before {
          background: #fff;
          width: calc(100% - 40px);
          @include tablet {
            width: calc(100% - 20px);
          }
        }
      }
    }
  }
  .social-links {
    display: flex;
    @include tablet {
      display: none;
    }
    img {
      width: 36px;
      height: 36px;

      &.instagram {
        width: 42px;
        height: 42px;
      }
    }
  }

  .social-links-mobile {
    display: none;
    @include tablet {
      display: block;
      align-self: center;
      position: relative;
      padding-top: 50px;
      .fa-lg {
        font-size: 2em !important;
      }
    }
  }
  .mobile-location {
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 36px;
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 500;
    @include tablet {
      display: none;
    }
    img {
      width: 24px;
      height: 24px;
    }
    &_address {
      display: flex;
      align-items: center;
    }
    &_phone {
      padding: 5px;
    }
  }
  .mobile-navigation {
    display: none;
    width: 60px;
    height: 60px;
    padding: 10px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      transform: rotate(180deg);
    }
    @include tablet {
      display: flex;
    }
  }
}
