@import "../../mixins";

.modal-main-section {
  min-height: 100vh;
  background-position: 50% 50%;
  background-size: cover;
  background-image: url("../../resources/img/bg_modal.png");

  .modal-content {
    width: 800px;
    margin: auto;
    text-align: center;

    @include tablet {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }

    .modal-section-container {
      @include tablet {
        display: block;
      }

      display: flex;
      justify-content: space-between;
    }

    h5 {
      color: rgba(255, 255, 255, 0.8);
    }

    .section-right {
      @include tablet {
        text-align: center;
      }

      text-align: left;
    }

    .section-left {
      @include tablet {
        text-align: center;
      }
      text-align: left;
    }

    .social-links {
      margin: auto;
      width: 200px;
      padding-top: 30px;
      margin-top: 20px;
      padding-bottom: 20px;
      border-top: 1px solid #C4C4C4;

      a {
        margin: 0 10px;
      }
    }

    .modal-btn {
      //#F2F2F2
      background-image:  linear-gradient(rgba(#F2F2F2, 0.7), rgba(#F2F2F2, 0.7));
      border-left: 30px solid #000;
      color: #070707;
    }

    .modal-close {
      text-align: right;
      padding-top: 100px;
    }

    .close-btn {
      background-image: linear-gradient(rgba(255, 0, 0, 0.7), rgba(255, 0, 0, 0.7));
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-clip: border-box;
      background-size: cover;
      border: none;
      padding: 10px 10px 7px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
