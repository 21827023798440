@import './mixins';
@font-face {
  font-family: Brudnota;
  src: url("/resources/fonts/Brudnota.otf") format("opentype");
}

* {
  box-sizing: border-box;
  text-transform: uppercase;
  font-family: 'Brudnota';
  font-weight: 500;
  outline: none ;
  color: #fff;
}

html {
  font-size: 10px;
  @include tablet {
    font-size: 8px;
  }
}

.App {
  position: relative;
  min-height: 100vh;
  p {
    line-height: 1.1em;
  }
}

h1 {
  font-size: 4rem;
  font-weight: 900;
}

h2 {
  font-size: 3.6rem;
  font-weight: 700;
}

h3 {
  font-size: 3.2rem;
  font-weight: 700;
}

h4 {
  font-size: 2.4rem;
  font-weight: 600;
}

h5 {
  font-size: 2rem;
  font-weight: 500;
}

h6 {
  font-size: 1.6rem;
  font-weight: 500;
}
