@import "../../../../mixins";

.mapImage{
  background-image: url("../../../../resources/img/map.png");
  width: 100%;
  height: 600px;

  @include tablet {
    background-image: url("../../../../resources/img/map-mobile.png");
  }
}
