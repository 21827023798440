@mixin tablet {
  @media (max-width: 820px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 600px) {
    @content;
  }
}
